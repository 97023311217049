<template>
    <v-dialog
        v-if="visible"
        v-model="visible"
        transition="dialog-top-transition"
        scrollable
        persistent
        max-width="550"
    >
        <v-card class="modal-maincard">

            <v-toolbar dark flat dense>
                <v-toolbar-title>{{ $t(title) }}</v-toolbar-title>
            </v-toolbar>

            <v-card-text class="wrapperFormModal">                

                <div class="notify-sign-error-legend">

                    <p>
                        {{ $t("Не_удалось_подписать_уведомление") }}. <span class="red--text">{{ $t("Проверьте_наличие_ЭЦП") }}!</span><br/>
                        {{ $t(promptDescriptionKey) }}?
                    </p>

                    <p>
                        <span class="text-uppercase">
                            "{{ $t("Да") }}"
                        </span>
                        - {{ $t(okDescriptionKey) }}. <br/>

                        <span class="text-uppercase">
                            "{{ $t("Нет") }}"
                        </span>
                        - {{ $t(cancelDescriptionKey) }}. <br/>

                        <span class="text-uppercase">
                            "{{ $t("Повторить") }}"
                        </span>
                        - {{ $t(retryDescriptionKey) }}. <br/>

                    </p>
                </div>

            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn 
                    color="cyan"
                    text
                    depressed
                    @click="ok"
                >
                    {{ $t("Да") }} 
                </v-btn>

                <v-btn
                    color="blue-grey"
                    text
                    depressed
                    @click="cancel"
                >
                    {{ $t("Нет") }}
                </v-btn>

                <v-btn 
                    color="blue-grey"
                    text
                    depressed
                    @click="retry"
                >
                    {{ $t("Повторить") }} 
                </v-btn>                

            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: "NotifySignErrorConfirmDlg",
    data () {
        return {
            title: "Требуется_подтверждение",

            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            }
        }
    },
    computed: {
        ...mapGetters('dialogs/notifySignErrorConfirm', { visible: 'isVisible', type: 'getType' }),
        promptDescriptionKey() {
            return this.type === 'REJECT' ? "Продолжить_отклонение_без_подписи_уведомления" : "Продолжить_регистрацию_без_подписи_уведомления" ;
        },
        okDescriptionKey() {
            return this.type === 'REJECT' ? "Продолжить_отклонение_без_подписи_уведомления" : "Продолжить_регистрацию_без_подписи_уведомления" ;
        },
        retryDescriptionKey() {
            return this.type === 'REJECT' ? "Повторить_попытку_подписи_уведомления_и_отклонение_документа" : "Повторить_попытку_подписи_уведомления_и_регистрацию_документа" ;
        },
        cancelDescriptionKey() {
            return this.type === 'REJECT' ? "Отменить_отклонение_и_вернуться_в_карточку_документа" : "Отменить_регистрацию_и_вернуться_в_карточку_документа" ;
        }
    },
    methods: {
        ...mapActions('dialogs/notifySignErrorConfirm', ['ok', 'retry', 'cancel'])
    }
}
</script>